import React, { useContext, useState } from 'react';
import Fichiers from '../class/file';
import Directory from '../class/directory';
import ContextMenu from './ContextMenu';
import useFetchAuth from '../hook/useFetchAuth';
import { authcontext } from '../contexte/AuthContext';


const Dosier = ({ dir, DIR, setDir,className,setActiveDir,onDoubleClick}) => {
    const {authFetch} = useContext(authcontext)
    const fetchAuth = useFetchAuth()

    const [showContext,setShowContext] = useState(false)
    const [positionContext,setPositionContext] = useState({top:0,left:0})
    function toogleExpand(e) {
        e.stopPropagation()
        let cloneDIR = {...DIR}
        const directory = Directory.findDir(dir.path,cloneDIR)
        directory.expand = !directory.expand
        setDir(cloneDIR)     
    }

    function drop(e) {
        e.stopPropagation();
        e.preventDefault()
        console.log(dir.path)
        const dt = e.dataTransfer
        const files = dt.files 
        for (const file of files){
            const fichier = new Fichiers({name:file.name,type:file.type,data:file,dir:dir.path,path:URL.createObjectURL(file),state:'upload'})
            try {
                const copyDir = {...DIR}
                const fichierInDir = Directory.findFileByName(fichier,copyDir)
                fichierInDir.state = 'remplacer'
                fichierInDir.data =  file
                setDir(copyDir)
            } catch (error) {
                if(error.message === "file doesn't exist"){
                    setDir((prevDir)=>{
                        const copyDir = {...prevDir}
                        const directory = Directory.findDir(dir.path,copyDir)
                        directory.child.push(fichier)
                        return copyDir
                    })
                    authFetch.post('/uploadImg',fichier.upload())
                    .then(()=>{
                        setDir((prevDir)=>{
                            const copyDir = {...prevDir}
                            const fichierInDir = Directory.findFileByName(fichier,copyDir)
                            console.log('uploadok')
                            fichierInDir.state = 'ok'
                            fichierInDir.path = fichier.dir + '/' + fichier.name
                            console.log(fichierInDir)
                            return copyDir
                        }) 
                    }).catch((err)=>{
                        switch (err) {
                            case 409 || 500:

                                setDir((prevDir)=>{
                                    const copyDir = {...prevDir}
                                    const fichierInDir = Directory.findFileByName(fichier,copyDir)
                                    fichierInDir.state = err === 409 ? 'remplacer' : 'nok'
                                    console.log(fichierInDir)
                                    return copyDir
                                }) 
                                break;
                    
                            default:
                                console.error(err)
                                break;
                        }
                        console.log(err)
                    })
                }else{
                    console.error(error)
                }
            }
        }
        

    }

    function DragOver(e) {
        e.stopPropagation();
        e.preventDefault()
    }

    function DragEnter(e) {
        e.stopPropagation();
        e.preventDefault()
    }

    const handelContext = (e)=>{
        e.preventDefault()
        e.stopPropagation()
        setShowContext(false)
        console.log(e)
        setPositionContext({
            top:e.clientY,
            left:e.clientX
        })
        console.log(positionContext )
        setShowContext(true)
    }

    const deleteDir = (e)=>{
        e.stopPropagation()
        const cloneDIR = {...DIR}
        const directory = Directory.findDir(dir.dir,cloneDIR)
        const i = directory.child.findIndex((element)=>(element.path === dir.path))
        console.log(directory.child[i])
        authFetch.post('/delete',dir.deleteDir())
        .then(()=>{
            directory.child.splice(i,1)
            setDir(cloneDIR)
        }).catch((err)=>{
            console.error(err)
        })
    }

    const handelNewDIr = (e)=>{
        e.stopPropagation()
        const cloneDIR = {...DIR}
        const directory = Directory.findDir(dir.dir,cloneDIR)
        directory.child.push(new Directory({name:'new',state:'new',type:'directory',child:[],dir:directory.path}))
        setDir(cloneDIR)
    }

    const newDir = (e)=>{
        e.stopPropagation()
        if(e.key === "Enter"){
            authFetch.post('/newDir',dir.newDir(e.target.value))           
           .then((newDir)=>{
                const cloneDIR = {...DIR}
                const directory = Directory.findDir(dir.dir,cloneDIR)
                console.log(directory)
                const i = directory.child.findIndex((element)=>(element.state === 'new'))
                directory.child.splice(i,1)
                directory.child.push(new Directory(newDir))
                setDir(cloneDIR)

            }).catch((err)=>{
                console.error(err)
            })
        }
    }


    if(className.includes('dir--side-bar')) {return (
        <div className= {`dir contenair ${className}`}
            onDragEnter={(e) => { DragEnter(e) }}
            onDragOver={(e) => { DragOver(e) }}
            onDrop={(e) => { drop(e) }}
            onDoubleClick={onDoubleClick}
        >
            <div className='contenair--space-evently contenair'>
                <div className='dir__name contenair'>
                    {!dir.expand ? <img src='/expand_more.png' alt="expand" onClick={toogleExpand}/> : <img src='/expand_less.png' alt="retract" onClick={toogleExpand}/>}
                    <img src="/folder.png" alt="" />
                    <span>{dir.name}</span>
                </div>
                {dir.expand ? <div className='dir__child'>
                {
                    dir.child.map((element)=>{
                        if(element.type === "directory"){
                            return <Dosier onClick={(e)=>{ e.stopPropagation()}} key={'sideDosier/'+element.name}  onDoubleClick={(e)=>{e.stopPropagation();e.preventDefault();setActiveDir(element)}} dir={element} DIR={DIR} setDir={setDir} setActiveDir={setActiveDir} className={'dir--side-bar'}/>
                        }
                        return null
                    })
                }
                </div>:null}
            </div>
        </div>
    );}

    if(className.includes('dir--main')) { 
        if(className.includes('dir--edit')) {return(
            <div className= {`dir contenair ${className}`}  
        >
            <div className='contenair--space-evently contenair'>
                <div className='dir__name contenair'>
                    <img src="/folder.png" alt="" />
                    <input autoFocus onKeyDown={newDir}></input>
                </div>
            </div>
        </div>
        )}

        return (<div className= {`dir contenair ${className}`}
            onDragEnter={(e) => { DragEnter(e) }}
            onDragOver={(e) => { DragOver(e) }}
            onDrop={(e) => { drop(e) }}
            onDoubleClick={onDoubleClick}
            onContextMenu={handelContext}
        >
            <div className='contenair--space-evently contenair'>
                <div className='dir__name contenair'>
                    <img src="/folder.png" alt="" />
                    <span>{dir.name}</span>
                </div>
            </div>
            {showContext && <ContextMenu position={positionContext} setShowContext={setShowContext}>
                    <div onClick={deleteDir} className='context-menu__option'>suprimer</div>
                    <div onClick={handelNewDIr} className='context-menu__option'>nouveau dosier</div>
                </ContextMenu>}
        </div>)
    }
    if(className.includes('dir--parcourir')){
        return(<div className= {`dir contenair ${className}`}
        onDoubleClick={()=>{setActiveDir(dir)}}
    >
        <div className='contenair--space-evently contenair'>
            <div className='dir__name contenair'>
                <img src="/folder.png" alt="" />
                <span>{dir.name}</span>
            </div>
        </div>
    </div>

        )
    }
};

export default Dosier;