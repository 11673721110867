import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import authorization from '../function/authorization'
import { authcontext } from './AuthContext'

export default function Admin() {
    const {user} = useContext(authcontext)
    if(!authorization(user.roles,[1000,2021,2022,2023,2024,1011,1012,1013])) return <Navigate to={'/home'}></Navigate>
  return (
    <>
            <Outlet></Outlet>
    </>
  )
}
