import React, { useContext, useEffect, useState } from 'react';
import get from '../function/get';
import Loading from '../componant/Loading';
import CrewCard from '../componant/crew/CrewCard';
import postJson from '../function/postJson';
import Carousel from '../componant/Carousel';
import useFetchAuth from '../hook/useFetchAuth';
import { useLocation, useNavigate } from 'react-router-dom';
import { authcontext } from '../contexte/AuthContext';
import ImgFetch from '../componant/ImgFetch';


const Home = () => {
  const {authFetch} = useContext(authcontext)
  const [loading,setLoading] = useState(true)
  const [crews,setCrews] = useState([])
  const [filtre,setFiltre] = useState('all')
  const [crewsFiltre, setCrewsFiltre] = useState(crews)
  const [newCrew,setNewCrew] = useState(false)
  const nav = useNavigate()
  const location = useLocation()
  const fetchAuth = useFetchAuth()

    useEffect(()=>{
      if(authFetch !== null){
        authFetch.get('/crew').then((crew)=>{
          setCrews(crew)
          setLoading(false)
        }).catch((err)=>{
          console.error(err)
          switch (err) {
            case 404:
              setLoading(false)
              break;
            default:
              console.log(err)
              break;
          }
        })
      }
  
      
    },[authFetch])

    useEffect(()=>{
      if(filtre === 'all'){
        setCrewsFiltre(crews)
      }else{
        setCrewsFiltre(crews.filter((crew)=>(crew.crewmate.length === filtre)))
      }
    },[crews,filtre])

    const save = (crew)=>{
      authFetch.postJson('/crew',{crew:crew})
      .then((crew)=>{
        setCrews([...crews,crew])
        setNewCrew(false)
      }).catch((err)=>{
        console.error(err)
      })
    }

    if(loading){
      return <Loading/>
    }
    return (
        <div className='contenair contenair--centre home'>
          <h1 className='titel'>Vos équipage</h1>
          <div className='contenair contenair--centre contenair--space-evently home__filtre'>
            <button onClick={()=>{setFiltre(2)}} className = { filtre === 2 ? "btn btn--transparent btn--selected": 'btn--transparent btn'} ><ImgFetch src='/img/public/2 joueurs.png'/></button>
            <button onClick={()=>{setFiltre(3)}} className = { filtre === 3 ? "btn btn--transparent btn--selected": 'btn--transparent btn'}><ImgFetch src='/img/public/3 joueurs.png'/></button>
            <button onClick={()=>{setFiltre(4)}} className = { filtre === 4 ? "btn btn--transparent btn--selected": 'btn--transparent btn'}><ImgFetch src='/img/public/4 joueurs.png'/></button>
            <button onClick={()=>{setFiltre(5)}} className = { filtre === 5 ? "btn btn--transparent btn--selected": 'btn--transparent btn'}><ImgFetch src='/img/public/5 joueurs.png'/></button>
            <button onClick={()=>{setFiltre('all')}} className={ filtre === 'all' ? "btn btn--selected": 'btn'}>all</button>
          </div>
          <Carousel className='wide'>
              {crewsFiltre.length > 0 ? crewsFiltre.map((crew,index)=>(<CrewCard key={`crew/${index}`} crew={crew}/>)):<div className='crew__title'>Auncun équipage</div>}
              <div className='crew__placeholder'>
                {newCrew ? <CrewCard save={save} key={`crew/new`} setNewCrew={setNewCrew} className={'crew__card--edit'}/>:
                <div onClick={()=>{setNewCrew(true)}} className='crew__card contenair btn contenair--centre'>
                  <button className='btn btn--add contenair contenair--centre'><img src="/add.png" alt="add" /></button>
                </div>
              
              }
              </div>
          </Carousel>
        </div>
    );
};

export default Home;