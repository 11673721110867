import React, { useContext, useState } from 'react'
import { adminContext } from '../contexte/AdminContexte'
import Dosier from './Dosier'
import Fichier from './Fichier'
import Directory from '../class/directory'


export default function Parcourir({select,closeWindow}) {
    const {dirs} = useContext(adminContext)
    const [activeDir,setActiveDir] = useState(dirs)
    const [selectedFile,setSelectedFile] = useState('')

    const parentDir = ()=>{
        setActiveDir(Directory.findDir(activeDir.dir,dirs))
    }
  return (
    <div className='parcourir contenair contenair--column'>
        <div className='contenair contenair--centre parcourir__header'>
            <div className='parcourir__path'>{activeDir.path}</div>
            <img onClick={parentDir} src="/arrow_drop.png" alt="/parent dir" />
        </div>
        <div className='parcourir__body contenair'>
            {activeDir.child.map((element)=>{
                console.log(element)
                if(element.type === 'directory'){
                    return <Dosier key={`dir/${element.name}`} dir={element} setActiveDir={setActiveDir} className={'dir--parcourir'}/>
                }
                return <Fichier key={`file/${element.name}`} file={element}  select={element.path === selectedFile ? true:false} setSelect={setSelectedFile} className={'file--parcourir'}/>
            })}
        </div>
        <div className="parcourir__footer contenair contenair--centre">
            <div className='parcourir__path'>{selectedFile}</div>
            <button onClick={()=>{select(selectedFile)}} className='btn btn--content'><img src="/done.png" alt="valider"/></button>
        </div>
    </div>
  )
}
