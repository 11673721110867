import React, { useContext, useEffect, useRef, useState } from 'react'
import useFetchAuth from '../hook/useFetchAuth'
import Loading from '../componant/Loading'
import Actions from '../componant/Actions'
import { authcontext } from '../contexte/AuthContext'
import authorization from '../function/authorization'
import Modal from '../componant/Modal'
import GereeRole from '../componant/gerre les roles/GereeRole'
import Popup from '../componant/Popup'

export default function GereeCompte() {
    
    const authFetch = useFetchAuth()
    const {user,authServeurFetch} = useContext(authcontext)
    
    const [userSelect,setUserSelect] = useState(false)
    const [users,setUsers] = useState(false)
    const [showAllUsers,setShowAllUsers] = useState(false)
    const [popUp,setPopUp] = useState(false)
    
    const actions = [
        {
            html:'Suprimer',
            auth:[1012,1000],
            state:'accepted',
            action: (id)=>{
                setPopUp(<Popup yes={()=>{
                    authServeurFetch.patchJson('/deleteUser',{userId:id}).then(()=>{
                        setUsers((prev)=>{
                            const copyPrev = [...prev]
                            const index = prev.findIndex((user)=>(user.id === id))
                            console.log(index)
                            copyPrev.splice(index,1)
                            return copyPrev
                        })
                        setPopUp(false)
                    })
                }} no={()=>{setPopUp(false)}} titel={'Suprimer utilisateur?'}>voulez-vous vraiment suprimer cette utilistateur</Popup>)
                
                
            }
        },
        {
            html:'Accepter',
            auth:[1011,1000],
            state:'pending',
            action: (id)=>{
                setPopUp(<Popup yes={()=>{
                    authServeurFetch.patchJson('/accept-user',{userId:id,state:'accepted'}).then(()=>{
                        setUsers((prev)=>{
                            const copyPrev = [...prev]
                            const index = prev.findIndex((user)=>(user.id === id))
                            prev[index].state = 'accepted'
                            return copyPrev
                        })
                        setPopUp(false)
                    })
                }} no={()=>{setPopUp(false)}} titel={'Suprimer utilisateur?'}>voulez-vous vraiment accepter cette utilistateur</Popup>)
                
                
            }
        },
        {
            html:'Refuser',
            auth:[1011,1000],
            state:'pending',
            action: (id)=>{
                setPopUp(<Popup yes={()=>{
                    authServeurFetch.patchJson('/accept-user',{userId:id,state:'deny'}).then(()=>{
                    setUsers((prev)=>{
                        const copyPrev = [...prev]
                        const index = prev.findIndex((user)=>(user.id === id))
                        copyPrev.splice(index,1)
                        return copyPrev
                    })
                    setPopUp(false)
                })
                }} no={()=>{setPopUp(false)}} titel={'Suprimer utilisateur?'}>voulez-vous vraiment accepter cette utilistateur</Popup>)
                
            }
        },
        {
            html:'gerer le premission',
            auth:[1013,1000],
            state:'accepted',
            action:  (id)=>{
                const user = users.findIndex((user)=>(user.id === id))
                setUserSelect(user)
            }
        },
    ]

    function modifyRoles (id,role){
            setUsers((prev)=>{
                const users = [...prev]
                const user = users.find((us)=>(us.id === id))
                const roleIndex = user.roles.findIndex((r)=>(r === role.code))
                if(roleIndex > -1 && role.state === false){
                    user.roles.splice(roleIndex,1)
                }else if(roleIndex === -1 && role.state === true){
                    user.roles.push(role.code)
                }
                return users
            })
    }


    useEffect(()=>{
        authServeurFetch.get('/userManage').then((users)=>{
            setUsers(users)
        })

    },[])

    if (users === false) return <Loading/>
  return (
    <div className='contenair contenair--centre manageAccount'>
        {}
        {userSelect !== false ? <Modal className='modal--centre' toggleModal={()=>{setUserSelect(false)}}>{<GereeRole close={()=>{setUserSelect(false) }} modifyRoles={modifyRoles} user={users[userSelect]}/>}</Modal> : null}
        {popUp !== false ? <Modal className='modal--centre' toggleModal={()=>{setPopUp(false)}}>{popUp}</Modal> : null}
        <div>
            <input onChange={(e)=>{setShowAllUsers(e.target.checked)}} type="checkbox" id='showAllUsers'/>
            <label htmlFor="showAllUsers">Afficher uniquement les utilisateurs en attente</label>
        </div>
        <table>
            <thead>
                <tr>
                    <th>Username</th>
                    <th>Email</th>
                    <th>Statue</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {
                users.filter((u)=>{
                    if(!showAllUsers) return true
                    return u.state === 'pending'
                }).map((u)=>(
                    <tr key={u.id}>
                        <td>{u.username}</td>
                        <td>{u.email}</td>
                        <td>{u.state === 'pending' ? 'en attentante':'accepté(e)'}</td>
                        <td>{<Actions options={actions.filter((action)=>((authorization(user.roles,action.auth) && u.state === action.state))).map((action)=>({...action,data:u.id}))}/>}</td>
                    </tr>
                ))
                }
            </tbody>
        </table>
    </div>
  )
}
