import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './page/Home';
import Desktop from './componant/desktop/Desktop';
import AdminContexte from './contexte/AdminContexte';
import AuthContext from './contexte/AuthContext';
import Login from './page/Login';
import Singin from './page/Singin';
import Private from './contexte/Private';
import Expedition from './page/Expedition';
import MyAccount from './page/MyAccount';
import CreeCompte from './page/CreeCompte';
import PasswordForgot from './page/PasswordForgot';
import ResetPassowrd from './page/ResetPassowrd';
import GereeCompte from './page/GereeCompte';
import Admin from './contexte/Admin';


function App() {
  return (
    <div className="App">
      <AuthContext>
        <Routes>
          <Route path='/login' element={<Login/>}/>
          <Route path='/cree-un-compte' element={<CreeCompte/>}/>
          <Route path='/mot-de-passe-oublier' element={<PasswordForgot/>}/>
          <Route path='/reinitialiser-mot-de-passe/:token' element={<ResetPassowrd/>}/>
          <Route path='/' element ={<Private/>}>
            <Route path='/home' element ={<Home/>}/>
            <Route path='/expedition/:crewId/:expeditonId' element={<Expedition/>}/>
            <Route path='/mon-compte' element={<MyAccount/>}/>
            
              <Route path='/admin' element={<Admin/>}>
                <Route path='/admin/manage-user' element={<GereeCompte/>}/>
                <Route path='/admin/app' element={<Desktop/>}/>
              </Route>
            
          
          </Route>
        </Routes>
      </AuthContext>
    </div>
  );
}

export default App;
