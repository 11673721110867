import React, { useContext, useEffect, useState } from 'react'
import GroupForm from '../componant/form/GroupForm'
import useFetch from '../hook/useFetch'
import { authcontext } from '../contexte/AuthContext'
import { useNavigate } from 'react-router-dom'

export default function CreeCompte() {

    const [message,setMessage] = useState({message:"",type:""})
    const fetchPerso = useFetch()
    const {user} = useContext(authcontext)
    const nav = useNavigate()

    const form = {
        titel:'Crée un compte',
        save:(data)=>{
            return new Promise((resolve,reject)=>{
                try {
                    fetchPerso('https://auht-api.edo.ovh/singin','postJson',data).then(()=>{
                        resolve('demande de creation envoyer')
                    }).catch((err)=>{
                        switch (err) {
                            case 409:
                                reject(new Error('Un compte existe déja avec cette email'))
                                break;
                            case 400:
                                reject(new Error('veillez rensegner tout les champs'))
                                break
                            default:
                                reject(new Error('Une erreur est survenue'))
                                break;
                        }
                    })
                    
                } catch (error) {
                    reject(new Error('Une erreur est survenue'))
                }
            })
        },
        valid:(data)=>{
            console.log(data)
            return new Promise((resolve,reject)=>{
                const rexEmail = /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/gm
                let err = []

                if(data.email === ""){
                    err.push({for:'email',msg:'Veillez rensegner ce champ'})
                }else if(!rexEmail.test(data.email)){
                    err.push({for:'email',msg:'Veillez rensegner un email valide'})
                }

                if(data.username === ""){
                    err.push({for:'username',msg:'Veillez rensegner ce champ'})
                }

                if(data.password === ""){
                    err.push({for:'password',msg:'Veillez rensegner ce champ'})
                }else if(data.password !== data.confirmPassword){
                    err.push({for:'confirmPassword',msg:'Différent du mot de passe'})
                }

                if(err.length > 0){
                    reject(err)
                    return
                }
                    resolve()
                    
            })
        },
        fields:[
            {
                name:'email',
                label:"E-mail",
                type:'email',
                value:""
            },
            {
                name:'username',
                label:"Nom d'utilisateur",
                type:'text',
                value:""
            },
            {
                name:"password",
                label:'Mot de passe',
                type:'password',
                value:''
            },
            {
                name:"confirmPassword",
                label:'Confirmer mot de passe',
                type:'password',
                value:''
            }
        ],
        fieldsModify:false,
        options:[
            {
                name:'Se connecter',
                ref:'/login'
            }
        ]
        
    }
    useEffect(()=>{
        return()=>{
            let clonemesage = {...message}
            clonemesage.message = ''
            clonemesage.type = ''
            setMessage(clonemesage)
        }
    },[])

    useEffect(()=>{
        if(user){
            nav('/home') 
        }
    },[])
  return (
    <div className='contenair contenair--centre'>
            <div className='login__warpper contenair contenair--centre'>
                <img src="logo.png" alt='logo' className='menu_logo'/>
                <GroupForm options={form.options} titel={form.titel} save={form.save} valid={form.valid} input={true} key={'loginForm'} fields={form.fields} btnText={'Crée un compte'}/>
            </div>
        </div>
  )
}
