import React, { createContext, useContext, useEffect, useState } from 'react'
import Directory from '../class/directory'
import Loading from '../componant/Loading'
import { authcontext } from './AuthContext'
import { useNavigate } from 'react-router-dom'
import get from '../function/get'
import authorization from '../function/authorization'
import useFetchAuth from '../hook/useFetchAuth'
export const adminContext = createContext()
export default function AdminContexte({children}) {
  const {authFetch} = useContext(authcontext)
  const [dirs, setDirs] = useState({})
  const [loading,setLoading] = useState(true)
  const [regles,setRegles] = useState([])
  const [campagnes,setCampagnes] = useState([])
  const [seting,setSeting] = useState([])
  const {user} = useContext(authcontext)
  const nav = useNavigate()
  const fetchAuth = useFetchAuth()

  useEffect(()=>{
    
      Promise.all([
        authFetch.get('/get-dir')
        .then((json)=>{
          console.log(json)
           const dir = new Directory(json)
           console.log('dir',dir)
           setDirs(dir)
         }),
         authFetch.get('/regle')
         .then((regles)=>{
            setRegles(regles)
         }),
         authFetch.get('/seting')
         .then((seting)=>{
            setSeting(seting)
         }),
         authFetch.get('/campagne')
         .then((campagne)=>{
            setCampagnes(campagne)
         })
         
      ]).then(()=>{
        setLoading(false)
      }).catch((err)=>{
        console.error(err)
        nav('/home')
      })

},[])

  return (
    <adminContext.Provider value={{dirs,setDirs,regles,setRegles,campagnes,setCampagnes,seting,setSeting}}>{loading ? <Loading/>:children}</adminContext.Provider>
  )
}
