import React, { useState } from 'react'
import CustumSelect from './CustumSelect'

export default function Actions({options}) {
    const [toggle,setToggle] = useState(false)
    function close(e){
        e.stopPropagation()
        setToggle(false)
    }

  return (
    <div className='relative'>
        <i onClick={()=>{setToggle((prev)=>(!prev))}} className='material-symbols-outlined'> more_horiz</i>
        {toggle?<CustumSelect close={close} className='--relative' position={'--bottom'} options={options}/>:null}
    </div>

  )

}
