import React, { useEffect } from 'react'

export default function ContextMenu({position,children,setShowContext}) {

    useEffect(()=>{
        const handelClick = (e)=>{
            setShowContext(false)
        }
        console.log("eventcreate")
        document.addEventListener("click",handelClick)

        return ()=>{
            document.removeEventListener("click",handelClick)
        }
        
    },[setShowContext])
    const handelClick = (e)=>{
        e.stopPropagation()
        setShowContext(false)
    }
  return (
    <div onClick={handelClick} style={position} className='context-menu'>{children}</div>
  )
}
