import React, { useState } from 'react'

export default function ZoneText({text}) {
    
    const [speakOn,setSpeakOn] = useState(false)
    const synth = window.speechSynthesis

    const speak = ()=>{
        if(synth.speaking){
            synth.cancel()
            setSpeakOn(false)
            return
        }
        const specker = new SpeechSynthesisUtterance(text)
        synth.speak(specker)   
        setSpeakOn(true)
        specker.onend = (event)=>{
            setSpeakOn(false)
        }     
    }

  return (
    <div className='text contenair contenair--column'>
                    <div className='contenair--scroll text__content'>{text}</div> 
                    <div className='speak' onClick={speak}>
                        {speakOn ? <img src='/speakOff.png' alt='speak on'/>: <img src='/speakOn.png' alt='speak off'/>}
                    </div>          
</div>
  )
}


