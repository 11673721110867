import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useFetch from '../hook/useFetch'
import Loading from '../componant/Loading'
import Mesage from '../componant/Mesage'
import GroupForm from '../componant/form/GroupForm'
import patchJson from '../function/patchJson'

export default function ResetPassowrd() {

    const fetchPerso = useFetch()

    const {token} = useParams()
    const [loading,setLoading] = useState(true)
    const [err,setErr] = useState({message:false,type:'nok'})
    const [email,setEmail] = useState('')

    useEffect(()=>{
        fetchPerso(`https://auht-api.edo.ovh/valide-token-reset-password/${token}`,'get').then((email)=>{
            setEmail(email.email)
        })
        .catch((err)=>{
                switch (err) {
                    case 401:
                        setErr((prev)=>{
                            return {...prev,message:'Lien invalide'}
                        })
                        break;
                    case 403:
                        setErr((prev)=>{
                            return {...prev,message:'Lien expiré'}
                        })
                        break;
                        
                    default:
                        setErr((prev)=>{
                            return {...prev,message:'erreur'}
                        })
                        break;
                }
        }).finally(()=>{
            setLoading(false)
        })
    },[token])

    const form = {
        titel:'Reinitialiser mot de passe',
        save:(data)=>{
            return new Promise((resolve,reject)=>{
                try {
                    fetchPerso(`https://auht-api.edo.ovh/reset-password/${token}`,'patchJson',data).then(()=>{
                        resolve('Mot de passe reinitialiser')
                    }).catch((err)=>{
                        switch (err) {
                            case 400:
                                reject(new Error('Mot de passe non valid'))
                                break;
                            case 401:
                                reject(new Error('Lien invalide'))
                                break;
                            case 403:
                                reject(new Error('Lien expiré'))
                                break;
                            default:
                                reject(new Error('Une erreur est survenue'))
                                break;
                        }
                    })
                    
                } catch (error) {
                    console.error(err)
                    reject(new Error('Une erreur est survenue'))
                }
            })
        },
        valid:(data)=>{
            return new Promise((resolve,reject)=>{
                let err = []
                if(data.newPassword === ""){
                    err.push({for:'newPassword',msg:'Veillez rensegner ce champ'})
                }else if(data.confirmNewPassword !== data.newPassword){
                    err.push({for:'confirmNewPassword',msg:'Différent du mot de passe'})
                }

                if(err.length > 0){
                    reject(err)
                    return
                }
                    resolve()
                    
            })
        },
        fields:[
            {
                name:'newPassword',
                label:'Nouveau mot de passe',
                type:'password',
                value:''
            },
            {
                name:'confirmNewPassword',
                label:'Confirmer nouveau mot de passe',
                type:'password',
                value:''
            }
        ],
        fieldsModify:false,
        options:[
            {
                name:'Se connecter',
                ref:'/login'
            }
        ]
    }

    if(loading)return <Loading/>
    if(err.message !== false){
        return (
            <div className='contnair contenair centre'>
                <Mesage message={err}/>
            </div>
        )  
    }  
  return (
    <div className='contenair contenair--centre'>
            <div className='login__warpper contenair contenair--centre'>
                <img src="logo.png" alt='logo' className='menu_logo'/>
                <span>Boujour, pour reinitialiser le mot de passe du compte {email} rentrer un nouveau mot de passe</span>
                <GroupForm options={form.options} titel={form.titel} save={form.save} valid={form.valid} input={true} key={'loginForm'} fields={form.fields} btnText={'Reinitialiser mot de passe'}/>
            </div>
        </div>
  )
}
