import React, { useEffect, useState } from 'react'

export default function Try({attempt}) {
    const classname = attempt.help ? 'attempt attempt--help':`attempt attempt--${attempt.number}`
    const [DisplayInfo,setDisplayInfo] = useState(false)

    useEffect(()=>{
        if(DisplayInfo){
            document.addEventListener('click',hideInfo)
            console.log('info')
        }
    },[DisplayInfo])

    const showInfon = (e)=>{
        e.stopPropagation()
        console.log(e.target)
        if(!attempt.help){
            setDisplayInfo(true)
            
        }
    }

    const hideInfo = (e)=>{
        e.stopPropagation()
        console.log(e.target)
        document.removeEventListener('click',hideInfo)
        setDisplayInfo(false)
    }

  return (
    <button className={classname + ` btn btn--transparent `} onClick={showInfon}>
        
        {
                DisplayInfo ? 
                    <div className='attempt__info'>
                        <div className='contenair'>
                            <div className='contenair contenair--centre'>Commandant</div>
                            <span className='contenair contenair--centre'>{attempt.comander}</span>
                        </div>
                        <div className='contenair'>
                            <div className='contenair contenair--centre'>Fautif</div>
                            <span className='contenair contenair--centre'>{attempt.fautif}</span>
                        </div>
                    </div> :
                    null}
    </button>
  )
}
