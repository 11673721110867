import post from "../function/post"


class Fichiers{
    constructor({name,path,type,data = undefined,state = 'ok',dir}){
        this.name = name
        this.path = path
        this.type = type
        this.state = state
        this.data = data
        this.dir = dir
    }

    setState = (state)=>{
        this.state = state
    }

    upload =  (remplacer = false)=>{
            const data = new FormData()
            data.append('img',this.data)
            data.append("dirname",this.dir)
            data.append("remplacer",remplacer)
            return data  
    }

    deleteFile = ()=>{
            const data = new FormData()
            console.log(this.path)
            data.append('path',this.path)
            return data
    }
}

 
export default Fichiers