import React, { useContext, useEffect, useState } from 'react'
import ExpeditionCard from './expedition/ExpeditionCard'
import get from '../../function/get'
import Loading from '../Loading'
import Modal from '../Modal'
import ExpreditionNew from './expedition/ExpreditionNew'
import { authcontext } from '../../contexte/AuthContext'
import calcEfficatiter from '../../function/calcEffecatiter'
import Carousel from '../Carousel'
import useFetchAuth from '../../hook/useFetchAuth'

export default function CrewCard({save,className = '',closeNewCrew,crew={name:'',crewmate:[],expeditions:[],sucess:0}}) {
    const [modal,setModal] = useState(false)
    const {user,authServeurFetch} = useContext(authcontext)
    const [newCrew,setNewCrew] = useState({name:'',crewmate:[]})
    const [loading,setLoading] = useState(true)
    const [users,setUsers] = useState([])
    const [gest,setGest] = useState('')
    const fetchAuth = useFetchAuth()

    useEffect(()=>{
        if(className.includes('crew__card--edit')){
            addMate({name:user.username,id:user.id})
            authServeurFetch.get('/users').then((users)=>{
                setUsers(users)
                setLoading(false)
            })
        }
    },[className])

    const toggleModal= (e)=>{
        e.stopPropagation()
        setModal(!modal)
    }

    const addMate = (mate)=>{
        const cloneNewCrew = {...newCrew}
        cloneNewCrew.crewmate.push(mate)
        setNewCrew(cloneNewCrew)
    }
    const deleteMate = (index)=>{
        const cloneNewCrew = {...newCrew}
        cloneNewCrew.crewmate.splice(index,1)
        setNewCrew(cloneNewCrew)
    }
    const addGest = ()=>{
        if(gest !== ''){
            const cloneNewCrew = {...newCrew}
            cloneNewCrew.crewmate.push({name:gest})
            setNewCrew(cloneNewCrew)
            setGest('')
        }
    }

    if(className.includes('crew__card--edit')){
        if(loading){
            return <Loading/>
        }
        return (
        <div className={`crew crew__card overflow--hidden contenair contenair--column ${className}`}> 
            <div className='crew__card__name'><input onChange={(e)=>{setNewCrew({...newCrew,name:e.target.value})}} value={newCrew.name} type="text" name='name'/></div>
            <div className='crew__card__body overflow--hidden contenair contenair--column'>
                <div className='crew__card__crewmate--edit overflow--hidden contenair contenair--column contenair--centre'>
                    <div className='contenair contenair crew__card__crewmate contenair--centre '>
                        {newCrew.crewmate.map((mate,index)=>(
                        <div key={`new/mate/${index}`} className='crew__card__mate contenair contenair--centre'>
                            <div >{mate.name}</div>
                            <button onClick={()=>{deleteMate(index)}} className='btn btn--add'><img src="not_disturb.png" alt="add"/></button>
                        </div>
                        ))}
                    </div>
                </div>
                    <div className='contenair crew_card_crewmate_add overflow--hidden contenair contenair--column'>
                        <div className='crew_card_crewmate_add__gest contenair contenair--centre'>
                            <h3 className='titel'>Invité</h3>
                            <input type="text" value={gest} onChange={(e)=>{setGest(e.target.value)}} name='gest'/>
                            <button onClick={addGest} className='btn btn--add'><img src="/add.png" alt="add"/></button>
                        </div>
                        <div className='crew__card__crewmate__add__user overflow--hidden contenair contenair--column'>
                            <h3 className='titel'>Utilisateur</h3>
                            <div className='contenair contenair--scroll crew__card__crewmate__add__user__liste'>
                                {users.filter((user)=>(newCrew.crewmate.find((mate)=>(mate.id===user.id)) === undefined)).map((user)=>(
                                    <div className='user contenair contenair--space-between' key={user.id}>
                                        <div className='user__name'>{user.username}</div>
                                        <button onClick={()=>{addMate({name:user.username,id:user.id})}} className='btn btn--add'><img src="/add.png" alt="add" /></button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                <button onClick={()=>{save(newCrew)}} className='btn btn--big'>Enregistrer</button>
                
            </div>
        </div>
        )
    }
  return (
    <div className='crew crew__card overflow--hidden contenair contenair--column'>
        {modal && <Modal toggleModal={toggleModal}><ExpreditionNew toggleModal={toggleModal} crewId={crew.id}/></Modal>} 
            <div className='crew__card__name'>{crew.name}</div>
        <div className='crew__card__body overflow--hidden contenair contenair--column'>
            <div className='crew__card__crewmate contenair contenair--centre'>
                {crew.crewmate.map((mate,index)=>(<div key={`${crew.id}/mate/${index}`} className='crew__card__mate'>{mate.name}</div>))}
            </div>
            <div className='crew__card__stat contenair'>éfficacitée:  {calcEfficatiter(crew.stats.sucess,crew.stats.attempt)}%</div>
                <h3 className='titel' >Expeditions</h3>
            <div className='crew__card__expedition contenair contenair contenair--scroll contenair contrnair--no-wrap'>
                <Carousel> {crew.expeditions.length > 0 ? crew.expeditions.map((element,index)=>(<ExpeditionCard crewId={crew.id}  key={`${crew.id}/expe/${index}`} expediton={element}/>))  : <h4 className='titel'>Aucune expenition en cours</h4>}</Carousel>
                
                
                    <button onClick={toggleModal} className='btn btn--add contenair contenair--centre'><img src="/add.png" alt="add"/></button>
                
            </div>
        </div>
    </div>
  )
}
