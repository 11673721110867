import React, { useContext } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { authcontext } from '../../contexte/AuthContext'
import authorization from '../../function/authorization'
import SideBar from '../SideBar'
import useFetch from '../../hook/useFetch'

export default function Header() {
    
    const {user,setUser} = useContext(authcontext)
    const nav = useNavigate()

    const fetchPerso = useFetch()

    const links = [
        {
            ref:'/home',
            acces:[1099],
            name:'Home'
        }/* ,
        {
            ref:'/mon-compte',
            acces:[1099],
            name:'Mon compte'
        },
        {
            ref:'/admin',
            acces:[1000,2021,2022,2023,2024,1011,1012,1013],
            name:'Administration'   
        } */
        
    ]

    function logout(){
        fetchPerso('https://auht-api.edo.ovh/logout','delete').then(()=>{
            setUser(false)
            nav('/login',{replace:true})
        })
    }
    
  return (
    <div className='header contenair contenair--centre contenair--centre'>
        <nav className='contenair contenair--centre contenair--space-evently'>
            {links.map((link,index)=>{
                if(authorization(user.roles,link.acces)){
                    return <NavLink key={`link/${index}`} className={({isActive})=>(`link ${isActive ? 'link--active':""}`)} to={link.ref} replace={true} >{link.name}</NavLink>
                }
                return null
            })}
            <SideBar>
                
                    <NavLink className={({isActive})=>(`sideBar__link btn contenair contenair--centre contenair--space-evently ${isActive ? 'sideBar__link--active':""}`)} to={"/mon-compte"} replace={true}>
                        Mon compte
                        <i className=' material-symbols-outlined'>person</i>
                    </NavLink>

                    {authorization(user.roles,[1013,1011,1012,1000])?
                    
                    <NavLink className={({isActive})=>(`sideBar__link btn contenair contenair--centre contenair--space-evently ${isActive ? 'sideBar__link--active':""}`)} to={"/admin/manage-user"} replace={true}>
                        Greé les utilisateurs
                        <i className=' material-symbols-outlined'>manage_accounts</i>
                    </NavLink>
              
                :null}    
                
                {authorization(user.roles,[2021,2022,2023,2024,1000])?
                    
                        <NavLink className={({isActive})=>(`sideBar__link btn contenair contenair--centre contenair--space-evently ${isActive ? 'sideBar__link--active':""}`)} to={"/admin/app"} replace={true}>
                            Administration
                            <i className=' material-symbols-outlined'>shield_person</i>
                        </NavLink>
                  
                :null} 
                <div className='btn contenair contenair--centre contenair--space-evently' onClick={logout}>
                    <span>Se deconnecter</span>
                    <i className=' material-symbols-outlined'>logout</i>
                </div>
            </SideBar>
        </nav>
    </div>
  )
}
