import React, { useCallback, useContext, useEffect, useState } from 'react'
import useFetchAuth from '../../hook/useFetchAuth'
import Loading from '../Loading'
import CategorieRoles from './CategorieRoles'
import { authcontext } from '../../contexte/AuthContext'

export default function GereeRole({user,close,modifyRoles}) {
    const {authServeurFetch} = useContext(authcontext)
    const fetchAuth = useFetchAuth()

    const [roles,setRoles] = useState(false)

    function modifyroleHelper (role){
        modifyRoles(user.id,role)
    }

    function save (user){
        authServeurFetch.patchJson('/roles',{roles:user.roles,userId:user.id}).then(()=>{
            close()
        })
    }

    useEffect(()=>{
        authServeurFetch.get('/roles').then((roles)=>{
            roles = roles.reduce((prev,curent)=>{
                if(user.roles.includes(curent.code)){
                    curent.state = true
                }else{
                    curent.state = false
                }
                if(prev.has(curent.application)){
                    const newCat =  prev.get(curent.application)
                    newCat.push(curent)
                    return prev.set(curent.application,newCat)
                }
                return prev.set(curent.application,[curent])
            },new Map)
            
            setRoles(roles)
        })
    },[])
     

    if(roles === false) return <Loading/>
    console.log(roles)
  return (
    <div className='gereeRole contenair contenair--centre'>
        <h1>Geré les roles de {user.username}</h1>
        {Array.from(roles,(v)=>(<CategorieRoles modifyRole={modifyroleHelper} key={v[0]} name={v[0]} roles={v[1]}/>))}
        <div onClick={()=>{save(user)}} className='btn btn--blue'>Enregistrer</div>
    </div>
  )
}
