import React, { useState } from 'react'

export default function BtnBascule({onClick,initState = false}) {



    function toogle (){
            onClick(!initState)
    }
  return (
        <div onClick={toogle} className={`btn btn--noHover btn__bascule btn__bascule--${initState ? 'true': 'false'}`}>
            <div className={`btn__bascule__cursor btn__bascule__cursor--${initState ? 'true': 'false'}`} ></div>
        </div>
  )
}
