import ImgFetch from "../ImgFetch"

export default function RegleExpedition({setText, regle = {name:"nouvelle regle",instruction:"",img:"image.png",id:'new',jeuDeBase:""}}) {
    
    
    
        return (
            <button onClick={()=>{setText(regle.instruction)}} className='btn btn--content regle regle--expedition contenair contenair--centre'>
            <ImgFetch src={regle.img}  alt="icon regle"/>    
            <div className='regle__info--expedition'>
                <div className="regle.instruction">{regle.instruction}</div>    
            </div>
        </button> 
        )
    }
    

  
