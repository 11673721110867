import React, { useState } from 'react'
import Mision from './Mision'

export default function Campagne({ save,className, campagneInit = {name:'',jeuBase:'',misions:[],id:'new',lor:'',background:'',numberOfMision:0}}) {
  const [campagne,setCampagne] = useState(campagneInit)

  

  const handel = (e)=>{
    if(e.target.name === 'numberOfMision'){
        const cloneCampagne = {...campagne}
        const oldN = cloneCampagne.numberOfMision
        let n =  0
        if(e.target.value !== ""){
             n = parseInt(e.target.value) 
        }
        cloneCampagne.numberOfMision = n
        if(n - oldN >= 1){
            let  i = n - oldN
            let n2  = oldN + 1
        while(i > 0){
            cloneCampagne.misions.push({name:`mission n°${n2}`,regles:[],lor:'',number:n2,dificulty:0,special:false})
            i--
            n2++
        }
        }else if(n - oldN <= -1){
            cloneCampagne.misions.length = n
        }
        setCampagne(cloneCampagne)
    }else{
        setCampagne({...campagne,[e.target.name]:e.target.value})
    }
  }

  if(className.includes('campagne--edit')){
    return (
        <div  className={`campagne ${className} contenair contenair--column`}>
            <div className='campagne__header contenair' style={{backgroundImage:campagne.background}}>
                <div className='campagne__name contenair'>
                    <label htmlFor="name">Nom de la campagne: </label>
                    <input value={campagne.name} onChange={handel} name='name' type="text" />
                </div>
                <div className='campagne__lor contenair'><textarea value={campagne.lor} onChange={handel} name="lor" placeholder='lor de de la campagne' id="" cols="30" rows="10"></textarea></div>
                <div className="campagne__numberOfMision">
                    <label htmlFor="numberOfMision">Nombre de mision : </label>
                    <input value={campagne.numberOfMision} min={0} onChange={handel} type="number" name="numberOfMision" id="" />
                </div>
                <div className="campagne__jeu">
                    <label htmlFor="jeuBase">Jeu de base: </label>
                    {campagne.jeuBase}
                </div>
            </div>
            <div className='contenair contenair--centre'>
                <h1>liste des mission</h1>
            </div>
            <div className='campagne__misions contenair contenair--scroll'>
                
                {campagne.misions.map((mision,index)=>(<Mision key={mision.number} className={'mision--edit'} misionIndex={index} campagne={campagne} setCampagne={setCampagne}/>))}
            </div>
            <div className='contenair'>
                <button className='btn btn--big' onClick={()=>{save(campagne)}}>Enregistrer</button>
            </div>
        </div>
        )
  }

    return (
    <div  className={`campagne ${className} contenair`}>
        <div className='campagne__header' style={{backgroundImage:campagne.background}}>
            <div className='campagne__name'>{campagne.name}</div>
            <div className='camapgne__lor'>{campagne.lor}</div>
            <div className="campagne__numberOfMision">{`nombre de mision : ${campagne.numberOfMision}`}</div>
            <div className="campagne__jeu">{`jeu de base : ${campagne.jeuBase}`}</div>
        </div>
    </div>
  )
}
