import React, {  useCallback, useContext, useEffect, useState } from 'react'
import Regles from './Regles'
import postJson from '../../function/postJson'
import patchJson from '../../function/patchJson'
import Mesage from '../Mesage'
import { adminContext } from '../../contexte/AdminContexte'
import { desktopContext } from '../desktop/Desktop'
import useFetchAuth from '../../hook/useFetchAuth'
import { authcontext } from '../../contexte/AuthContext'


export default function GereeRegle() {
    const fetchAuth = useFetchAuth()

    const {authFetch} = useContext(authcontext)
    const [newRegle,setNewRegle] = useState(false)
    const {regles,setRegles} = useContext(adminContext)
    const [message,setMessage] = useState({message:'',type:''})
    const [jeuBase,setJeuBase] = useState("")
    const {seting,setSeting} = useContext(adminContext)
    const [activeSeting,setActiveSeting] = useState(false)
    const {parcourir} = useContext(desktopContext)
    const [extandRegleDificulter,setExtendRegleDificulter] = useState(false)
    const [extandRegleDetresse,setExtendRegleDetresse] = useState(false)
  useEffect(()=>{
    const razMessage = setTimeout(()=>{
      setMessage({...message,message:'',type:''})
    },5000)
    return ()=>{
      clearTimeout(razMessage)
    }
  },[message])

    const save = (regle,sync)=>{
      console.log(regle.id)
      const cloneRegles = [...regles]
      const cloneRegle = {...regle}
      if(cloneRegle.id === 'new'){
        delete cloneRegle.id
        authFetch.patchJson('/regle',{regle:cloneRegle})
        fetchAuth('https://the-crew-api.edo.ovh/regle','postJson',{regle:cloneRegle})
        .then((id)=>{
          regle.id = id
          cloneRegles.push(regle)
          setRegles(cloneRegles)
          setNewRegle(false)
        }).catch((err)=>{
          console.error(err)
          switch (err.message) {
            case "409":
              setMessage({...message,message:'Cette Regle éxiste déja',type:"nok"})
              break;
            case "500":
              setMessage({...message,message:'Une erreur est survenue',type:"nok"})
            break
            default:
              break;
          }
        }) 
      }else{
        authFetch.patchJson('/regle',{regle:cloneRegle})
       .then(()=>{
          sync(true)
        })
        .catch((err)=>{
          console.error(err)
          switch (err.message) {
            case "409":
              setMessage({...message,message:'Cette Regle éxiste déja',type:"nok"})
              break;
            case "500":
              setMessage({...message,message:'Une erreur est survenue',type:"nok"})
            break
            default:
              break;
          }
        }) 
      }
    }

    const selectJeu = (e)=>{
      const jeu = e.target.value
      console.log(seting)
      const activSeting = seting.find((element)=>(element.jeuBase === jeu))
      console.log(activSeting)
      setActiveSeting(activSeting)
      setJeuBase(jeu)
    }

    const setDificulty = useCallback((path)=>{
      const index = path.lastIndexOf('/')
      const lastIndex = path.length
      const dificultyDir = path.slice(0,index)
      const indexName = path.indexOf('.')
      const name = path.slice(index,indexName)
      const exe = path.slice(indexName,lastIndex)
      const prefixIndex = name.indexOf('_')
      if(prefixIndex > -1){
        activeSeting.dificultyDir = dificultyDir
        activeSeting.dificultyName = name.slice(0,prefixIndex+1)
        activeSeting.dificultyExt = exe
        setSeting([...seting])
        return
      }
      setMessage({...message,message:'Nom de fichier non valide (<nom>_<dificulter> attendu)',type:"nok"}) 
    },[seting,setMessage,message,activeSeting,setSeting]) 

    const setSignal = useCallback((path)=>{
      const index = path.lastIndexOf('/')
      const lastIndex = path.length
      const signalDir = path.slice(0,index)
      const indexName = path.indexOf('.')
      const name = path.slice(index,indexName)
      const exe = path.slice(indexName,lastIndex)
      const prefixIndex = name.indexOf('_')
      if(prefixIndex > -1){
        activeSeting.signalDir = signalDir
        activeSeting.signalName = name.slice(0,prefixIndex+1)
        activeSeting.signalExt = exe
        setSeting(...seting)
        return
      }
      setMessage({...message,message:'Nom de fichier non valide (<nom>_<dificulter> attendu)',type:"nok"}) 
    },[seting,setMessage,message,activeSeting,setSeting])
    
    const saveSeting = ()=>{
      authFetch.patchJson('/seting',{seting:activeSeting})
      .then((res)=>{
        
          setMessage({...message,message:'seting enregistrer!',type:"ok"})   
        }).catch((err)=>{
          setMessage({...message,message:'Une erreur est survenu',type:"nok"}) 
      })
    }

    const saveSetingRegle = (regle,sync)=>{
      const setingClone = activeSeting
      if(regle.id === 'diff'){
        setingClone.regleDificulty = regle
      }else{
        if(regle.id === 'dett'){
          setingClone.regleDetresse = regle
        }
      }
      authFetch.patchJson('/seting',{seting:setingClone})
      .then((res)=>{
        sync(true)
        setMessage({...message,message:'seting enregistrer!',type:"ok"})   
      }).catch((err)=>{
        setMessage({...message,message:'Une erreur est survenu',type:"nok"}) 
    })
    }

  return (
    <div className='geree-regle contenair contenair--column'>
      <div className='contenair contenair--centre geree-regle__header'>
        <Mesage message={message}/>
        <select value={jeuBase} onChange={selectJeu} name="jeu" id="">
          <option value="">-Sélectioner un jeu de base</option>
          <option value="espace">Espace</option>
          <option value="sous marin">Sous marin</option>
        </select>
      </div>
      {activeSeting ? <div className='conteanir contenair centre geree-regle__seting'>
          <div className='geree-regle__seting__dificulty contenair'>
            <div>Dossier difficulter: {activeSeting.dificultyDir}</div>
            <button onClick={(e)=>{parcourir(setDificulty,e)}} className='btn btn--blue contenair--centre'>...</button>
          </div>
          <div className='geree-regle__seting__signal contenair'>
            <div>Dossier signale: {activeSeting.signalDir}</div>
            <button onClick={(e)=>{parcourir(setSignal,e)}} className='btn btn--blue contenair--centre'>...</button>
          </div>
          <div>
            <button className='btn btn--content' onClick={()=>{setExtendRegleDificulter(!extandRegleDificulter)}}>Regle dificulter <img className='img img--expand' src={extandRegleDificulter ? '/expand_less.png':'/expand_more.png'} alt="" /></button>
            
            {extandRegleDificulter ? <Regles className={'edit'} jeubase={jeuBase} saveRegle={saveSetingRegle} parconrir={parcourir} regleinit={{...activeSeting.regleDificulty,id:'diff'}}/>:null}
            
          </div>
          <div>
            <button className='btn btn--content' onClick={()=>{setExtendRegleDetresse(!extandRegleDetresse)}}>Regle détresse <img className='img img--expand' src={extandRegleDetresse ? '/expand_less.png':'/expand_more.png'} alt="" /></button>
            
            {extandRegleDetresse ? <Regles className={'edit'} jeubase={jeuBase} saveRegle={saveSetingRegle} parconrir={parcourir} regleinit={{...activeSeting.regleDetresse,id:'dett'}}/>:null}
            
          </div>
          <button onClick={saveSeting} className='btn btn--blue btn--big'>Enregistrer</button>
      </div>:null}
      
      <div className='contenair contenair--scroll geree-regle__body'>
        {regles.filter((regle)=>(regle.jeuDeBase === jeuBase)).map((regle,index)=>(<Regles key={`regles/${index}`} className={'edit'} regleinit={regle} saveRegle={save} parconrir={parcourir}/>))}
        {newRegle  ? <Regles className={'edit'} jeubase={jeuBase} saveRegle={save} parconrir={parcourir}/> :jeuBase !=='' ? <button onClick={()=>{setNewRegle(true)}} className='btn'><img src="/add.png" alt="add" /></button>:null}
      </div>
    </div>
  )
}
