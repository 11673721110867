const Window = ({name,children,zIndex,onClick,close,position={top:0,left:0,height:500,widht:750}})=> {
    
    const moveWindow = (e)=>{
        const window = e.target.parentNode.parentNode
        const click = e
        let windowY = window.style.top.replace('px','')
        let windowX = window.style.left.replace('px','') 
        const move = (e)=>{
            const x = e.movementX
            const y = e.movementY
            windowX = parseInt(windowX) + x
            windowY= parseInt(windowY) + y
            window.style.top = `${windowY}px`
            window.style.left = `${windowX}px`
        }
        document.addEventListener('mousemove',move)
        document.onmouseup = ()=>{
            document.removeEventListener('mousemove',move)
        }
                        
    }
    
    const resize = (e)=>{
        const name = e.target.getAttribute("name")
        const window = e.target.parentNode
        let windowY = window.style.top.replace('px','')
        let windowX = window.style.left.replace('px','')
        let width = window.style.width.replace('px','')
        let height = window.style.height.replace('px','')
        const move = (e)=>{
            const x = e.movementX
            const y = e.movementY
            if(name === "bottomLeftCorner"){
                windowX = parseInt(windowX) + x
                width = parseInt(width) - x
                height = parseInt(height) + y
                window.style.left = `${windowX}px`
                window.style.width = `${width}px`
                window.style.height = `${height}px`				
                return
            }
            if(name === "bottomRightCorner"){
                width = parseInt(width) + x
                windowY= parseInt(windowY) + y
                height = parseInt(height) + y
                window.style.width = `${width}px`
                window.style.height = `${height}px`
                return
            }
            if(name === "topLeftCorner"){
                windowY= parseInt(windowY) + y
                width = parseInt(width) + x
                height = parseInt(height) - y
                window.style.top = `${windowY}px`
                window.style.width = `${width}px`
                window.style.height = `${height}px`
                return
            }
            if(name === "topRightCorner"){
                windowX = parseInt(windowX) + x
                windowY= parseInt(windowY) + y
                width = parseInt(width) - x
                height = parseInt(height) - y
                window.style.top = `${windowY}px`
                window.style.left = `${windowX}px`
                window.style.width = `${width}px`
                window.style.height = `${height}px`
                                
                return
            }
            if(name === "leftSide"){
                windowX = parseInt(windowX) + x
                width = parseInt(width) - x
                window.style.left = `${windowX}px`
                window.style.width = `${width}px`	
                return
            }
            if(name === "rightSide"){
                width = parseInt(width) + x
                window.style.width = `${width}px`
                return
            }
            if(name === "topSide"){
                windowY= parseInt(windowY) + y
                height = parseInt(height) - y
                window.style.top = `${windowY}px`
                window.style.height = `${height}px`	
                return
            }
            if(name === "bottomSide"){
                height = parseInt(height) + y
                window.style.height = `${height}px`
                return
            }
        }
        document.addEventListener('mousemove',move)
        document.onmouseup = ()=>{
            document.removeEventListener('mousemove',move)
        }
    }

  return (
    <div  className="window" style={{top:`${position.top}px`,left:`${position.left}px`,height: `${position.height}px`,width:`${position.widht}px`,zIndex:zIndex}}>
				<div  onMouseDown = {resize} name='bottomLeftCorner' className="window__left window__bottom window__corner--left"></div>
				<div  onMouseDown = {resize} name='bottomRightCorner' className="window__right window__bottom window__corner--right"></div>
				<div  onMouseDown = {resize} name='topRightCorner' className="window__left window__top window__corner--right"></div>
				<div  onMouseDown = {resize} name='topLeftCorner' className="window__right window__top window__corner--left"></div>
				<div  onMouseDown = {resize} name='leftSide' className="window__left window__side--W"></div>
				<div  onMouseDown = {resize} name='rightSide' className="window__right window__side--W"></div>
				<div  onMouseDown = {resize} name='topSide' className="window__top window__side--L"></div>
				<div  onMouseDown = {resize} name='bottomSide' className="window__bottom window__side--L"></div>
				<div   className="contenair contenair--centre contenair--no-warp window__top-bar">
					<div className ="window__top-bar__option contenair"></div>
					<div onMouseDown = {moveWindow} className="window__top-bar__title contenair contenair--centre">{name}</div>
					<div className="window__top-bar__navigation">
						<button onClick={close} className="btn btn--top-bar btn--close"><img src="/close.png" alt="close" /></button>
						<button className="btn btn--top-bar btn--expand"></button>
						<button className="btn btn--top-bar btn--minimize"></button>
					</div>
					
				</div>
				<div className="window__content contenair contenair--centre contenair--scroll">{children}</div>
				
			</div>
  )
}

export default Window