import React, { useContext, useEffect, useState } from 'react'
import get from '../../../function/get'
import Loading from '../../Loading'
import numToFix from '../../../function/numToFix'
import { useNavigate } from 'react-router-dom'
import useFetchAuth from '../../../hook/useFetchAuth'
import { authcontext } from '../../../contexte/AuthContext'

export default function ExpeditionCard({expediton,className='',select,crewId}) {
    const {authFetch} = useContext(authcontext)
    const [loading,setLoading] = useState(true)
    const [curentMission,setCurentMission] = useState(false)
    const [campagne, setCampagne] = useState({})
    const fetchAuth = useFetchAuth()
    const nav = useNavigate()

    useEffect(()=>{
        if(!className.includes('expedition__card--select')){

           authFetch.get(`/campagne/id/${expediton.campagneRef}`).then((campagne)=>{
                const refIndex = expediton.progress.findIndex((progres)=>(progres.state === 'in progress'))
                if(refIndex !== -1){
                    const missionIndex = campagne.misions.findIndex((mision)=>(mision.number === expediton.progress[refIndex].ref))
                    if(missionIndex !== -1){
                        setCurentMission(campagne.misions[missionIndex])
                    }
                }else{
                    setCurentMission(false)
                }
                setCampagne(campagne)
                setLoading(false)
            })
        
        }else{
            setCampagne(expediton)
            setLoading(false)
        }
    },[expediton,className])
    
    const start = ()=>{
        nav(`/expedition/${crewId}/${expediton.id}`)
    }

    if(loading){
        return <Loading/>
    }
    if(className.includes('expedition__card--select')){
        return (
            <div className='overflow--hidden expedition__card expedition__card--select expedition contenair contenair--column contenair--centre'>
                <div className='expedition__card__name contenair contenair--centre expedition__card__name--select'>{campagne.name} - {campagne.jeuBase}</div>
                <div className=' contenair contenair--column overflow--hidden expedition__card__body expedition__card__body--select'>
                    <div className='expedition__card__lor expedition__card__lor--select contenair--scroll'>{campagne.lor}</div>
                    <div className='expedition__card__info expedition__card__info--select contenair'>
                        <div>Nombre de mission: {campagne.numberOfMision}</div>
                    </div>
                    <button onClick={(e)=>{select(campagne.id,e)}} className='btn btn--big btn--blue'>{campagne.jeuBase ==='espace' ? 'Décolage':'Larguer les amarres'}</button>
                </div>
            </div>
          )
    }
    return (
    <div className='expedition__card expedition contenair contenair--column'>
        <div className='expedition__card__name contenair contenair--centre'>{campagne.name} - {campagne.jeuBase}</div>
        <div className='contenair contenair--column overflow--hidden expedition__card__body'>
            <div className='expedition__card__info contenair'>
                <div className='contenair'>Nombre de mission: {campagne.numberOfMision}</div>
                <div className='contenair'>Mission en cour:  {curentMission ? curentMission.name : "Expedition terminée" }</div>
                <div className='contenair'>Efficacitée: {expediton.stats.attempt === 0 ? '100%':`${numToFix((expediton.stats.sucess/expediton.stats.attempt)*100)}%`}</div>
            </div>
            {expediton.done ? null:<button onClick={start} className='btn btn--big btn--blue'>{campagne.jeuBase ==='espace' ? 'Décolage':'Larguer les amarres'}</button>}
            
        </div>
    </div>
  )
}
