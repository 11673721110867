import React from 'react'

export default function Icon({img,name,start,children}) {
  return (
    <div className='desktop__icon contenair contenair--centre' onDoubleClick={()=>{start({activity:children,name:name,img:img})}}>
        <img src={img} alt="" />
        <span>{name}</span>
    </div>
  )
}
