import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import ContextMenu from './ContextMenu';
import Directory from '../class/directory';
import useFetchAuth from '../hook/useFetchAuth';
import ImgFetch from './ImgFetch';
import { authcontext } from '../contexte/AuthContext';

const Fichier = ({file,DIR,setDir,className,select,setSelect}) => {
    const {authFetch} = useContext(authcontext)
    const fetchAuth = useFetchAuth()

    const [msg, setMsg] = useState(false)
    const [img, setimg] = useState("")
    const [showContext,setShowContext] = useState(false)
    const [positionContext,setPositionContext] = useState({top:0,left:0})
    useEffect(()=>{
    
        switch (file.state) {
            case "ok":
                setimg('/done.png')
                break
            case 'upload':
                setimg('/sync.png')
                break
            case 'remplacer':
                setMsg(`${file.name} existe déja voulez-vous le remplacer`)
                setimg('/not_disturb.png')
                break
            case 'nok':
                setMsg(`Une erreur c'est produite`)
                setimg('/not_disturb.png')
                break
            default:
                break;
        }
    },[file.state,file.name,setMsg])

    const handelContext = (e)=>{
        e.preventDefault()
        e.stopPropagation()
        setShowContext(false)
        console.log(e)
        setPositionContext({
            top:e.clientY,
            left:e.clientX
        })
        console.log(positionContext )
        setShowContext(true)
    }

    const deleteFile = ()=>{
        console.log("delete")
        authFetch.post('/delete',file.deleteFile())
        .then(()=>{
            const cloneDIR = {...DIR}
            const dir = Directory.findDir(file.dir,cloneDIR)
            const i = dir.child.findIndex((element)=>(element.name === file.name))
            dir.child.splice(i,1)
            setDir(cloneDIR)
        }).catch((err)=>{
            console.error(err)
        })

    }

    const remplacer = ()=>{
        authFetch.post('/uploadImg',file.upload(true))
        .then(()=>{

            setDir((prevDir)=>{
                const copyDir = {...prevDir}
                const fichierInDir = Directory.findFileByName(file,copyDir)
                console.log('uploadok')
                fichierInDir.state = 'ok'
                fichierInDir.path = file.dir + '/' +file.name
                console.log(fichierInDir)
                return copyDir
            }) 
            
            setMsg(false)
                        
        }).catch((err)=>{
            const copyDir = {...DIR}
            const fichierInDir = Directory.findFileByName(file,copyDir)
            switch (err) {
                case 409 || 500:

                    setDir((prevDir)=>{
                        const copyDir = {...prevDir}
                        const fichierInDir = Directory.findFileByName(file,copyDir)
                        fichierInDir.state = err === 409 ? 'remplacer' : 'nok'
                        console.log(fichierInDir)
                        return copyDir
                    }) 
                break;
                
                default:
                    console.error(err)
                    break;
            }
            console.log(err)
        })
    }
    
    const handelNewDIr = ()=>{
    const cloneDIR = {...DIR}
    const directory = Directory.findDir(file.dir,cloneDIR)
    directory.child.push(new Directory({name:'new',state:'new',type:'directory',child:[],dir:directory.path}))
    setDir(cloneDIR)
}
    
    if(className.includes('file--parcourir')){
        return(
        <div className={`file contenair ${className} ${select && 'file--selected'}`} onClick={()=>{setSelect(file.path)}}>
            <div className='file__info contenair'>
                <ImgFetch className='mignature_img' src={file.path} alt="miniature"/>
                <span className='file__name'>{file.name}</span>
            </div>      
        </div>
        )
    }

    return (
        <div className={`file contenair ${className}`} onContextMenu={handelContext}>
            <div className='file__info contenair'>
                <ImgFetch className='mignature_img' src={file.path} alt="miniature"/>
                <div className="file__state">
                    <span className='file__name'>{file.name}</span>
                    <img className={file.state} src={img} alt={img} />
                </div>
            </div>
            {
                msg && 
                <div>
                    <span>{msg}</span>
                    {file.state === "remplacer" && <div> <button onClick={remplacer}>oui</button> <button>non</button> </div>}
                </div> 
            }
            {showContext && <ContextMenu position={positionContext} setShowContext={setShowContext}>
                    <div onClick={deleteFile}  className='context-menu__option'>suprimer</div>
                    <div  onClick={handelNewDIr} className='context-menu__option'>nouveau dosier</div>
                </ContextMenu>}
                
            
        </div>
    );
};

export default Fichier;