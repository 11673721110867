import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import FetchAuthObject from '../class/FetchAuthObject';
import Loading from '../componant/Loading';


export const authcontext = createContext()
const AuthContext = ({children}) => {
    const nav = useNavigate()
    const [user, setUser] = useState(false)
    const [accessToken,setAccessToken] = useState('')
    const [login, setLogin] = useState(false)
    const [authFetch,setAuthFetch ] = useState(null)
    const [authServeurFetch,setAuthServeurFetch ] = useState(null)

    useEffect(()=>{
        fetch('https://auht-api.edo.ovh/token',{
            credentials: 'include'
        }).then((res)=>{
            if(res.status === 200){
                return res.json()
            }
            else{
                throw new Error(res.status)
            }
        }).then((data)=>{
            if(data){
                console.log('update')
                setUser(data.user)
                setAccessToken(data.accessToken)
                setAuthFetch(new FetchAuthObject(data.accessToken,'https://the-crew-api.edo.ovh','https://auht-api.edo.ovh/token'))
                setAuthServeurFetch(new FetchAuthObject(data.accessToken,'https://auht-api.edo.ovh','https://auht-api.edo.ovh/token'))
            }
            setLogin(true)
        }).catch((err)=>{
            console.log(err)
            setUser(false)
            setLogin(true)      
        })
    },[])
    
    return (
       <authcontext.Provider value={{user, setUser,setAccessToken,accessToken,authFetch,setAuthFetch,authServeurFetch,setAuthServeurFetch}}>
            {login?children:<Loading/>}
       </authcontext.Provider>
    );
};

export default AuthContext;