import React, { useContext, useEffect, useState } from 'react'
import useFetchAuth from '../hook/useFetchAuth'
import Loading from './Loading'
import { authcontext } from '../contexte/AuthContext'

export default function ({src,alt,onClick,className = ''}) {
    const fetchAuth = useFetchAuth()
    const {authFetch} = useContext(authcontext)
    const [img,setImg] = useState(false)
    useEffect(()=>{
        authFetch.get(src)
        .then((img)=>{
            setImg(URL.createObjectURL(img))
        }).catch((err)=>{
            console.error(err)
            setImg('/image.png')
        })
    },[src])

    if(!img) return <Loading/>
  
    return (
    <>
        <img className={className} onClick={onClick} src={img} alt={alt} />
    </>
  )
}
