import React, { useCallback, useContext, useState } from 'react'
import Regles from '../regles/Regles'
import CustumSelect from '../CustumSelect'
import { adminContext } from '../../contexte/AdminContexte'
import Modal from '../Modal'

export default function Mision({className,misionIndex,campagne,setCampagne}) {
    const mision = campagne.misions[misionIndex]
    const [toggleSelect,setToggleSelect] = useState(false)
    const [toggleSelectOptional,setToggleSelectOptional] = useState(false)
    const {regles} = useContext(adminContext)
    const [modal,setModal] = useState(false)
    

    const handel = (e)=>{
      const cloneCampagne = {...campagne}
      const mision = cloneCampagne.misions[misionIndex]
      if(e.target.name === 'dificulty'){

        setCampagne({...campagne,[e.target.name]:parseInt(e.target.value)})
        mision[e.target.name] = parseInt(e.target.value)
        setCampagne(cloneCampagne)
      }else{
        if(e.target.type === 'number'){
          mision[e.target.name] = parseInt(e.target.value) 
          setCampagne(cloneCampagne)
          return
        }
        mision[e.target.name] = e.target.value
        setCampagne(cloneCampagne)
      }
    }

    const handelIsInfinite = (e)=>{
      const cloneCampagne = {...campagne}
      const mision = cloneCampagne.misions[misionIndex]
      mision.isInfinite = !mision.isInfinite
      mision.type= "autre"
      setCampagne(cloneCampagne)
  }

  const handelIsDificultyInfinite = ()=>{
      const cloneCampagne = {...campagne}
      const mision = cloneCampagne.misions[misionIndex]
      mision.type= mision.type === "dificulty" ? "autre" : "dificulty"
      setCampagne(cloneCampagne)
  }

    const deleteRegle = (index)=>{
      const cloneCampagne = {...campagne}
      const mision = cloneCampagne.misions[misionIndex]
      mision.regles.splice(index,1)
      setCampagne(cloneCampagne)
    }

    const addRegle = useCallback((element)=>{
        const cloneCampagne = {...campagne}
        const mision = cloneCampagne.misions[misionIndex]
        mision.regles.push({...element,optional:false})
        setCampagne(cloneCampagne)
      },[campagne,setCampagne,misionIndex])

    const addOptinalRagle = useCallback((element)=>{
      const cloneCampagne = {...campagne}
      const mision = cloneCampagne.misions[misionIndex]
      console.log(element)
      mision.regles.push({...element,optional:true})
      setCampagne(cloneCampagne)
    },[campagne,setCampagne,misionIndex])

    const closeModal = (e)=>{
      e.stopPropagation()
      setModal(false)
      setToggleSelect(false)
      setToggleSelectOptional(false)
    }

    const openModal = (element)=>{
      setModal(element)
    }

    const selectOptionalRegle = ()=>{
      setToggleSelectOptional(true)
      openModal(<CustumSelect options={regles.filter((regle)=>(regle.jeuDeBase === campagne.jeuBase)).map((regle,index)=>({html:<Regles key={`reglesOptionalSelect/${index}`} className={'regle--campagne'} regleinit={regle}/>,data:regle}))} close={closeModal} select={addOptinalRagle}/>)
    }

    const selectRegle = ()=>{
      setToggleSelect(true)
      openModal(<CustumSelect options={regles.filter((regle)=>(regle.jeuDeBase === campagne.jeuBase)).map((regle,index)=>({html:<Regles key={`reglesSelect/${index}`} className={'regle--campagne'} regleinit={regle}/>,data:regle}))} close={closeModal} select={addRegle}/>)
    }

    if(className.includes('mision--edit')){
        
        return (
          <div className={`mision ${className} contenair`}>
             {modal !== false ? <Modal className={'modal--relative'} toggleModal={closeModal}>{modal}</Modal> :null}
              <div className='mision__header contenair'>  
                <div className='mision__name contenair'><label htmlFor="name">Nom de la mision: </label> <input onChange={handel} value={mision.name} name='name' type='text'/> </div>
                <div className='contenair contenair--centre regle__type'>
                    <label htmlFor='isInfinite'>Mission infinie: </label>
                    <input  name='isInfinite' type='checkbox' onChange={handelIsInfinite} value={mision.isInfinite} checked={mision.isInfinite}></input>
                    {mision.isInfinite ? 
                      <div>
                          <label htmlFor='type'>dificulté infinie: </label>
                          <input  name='type' type='checkbox' onChange={handelIsDificultyInfinite} value={mision.type === 'dificulty'} checked={mision.type === 'dificulty'}></input>
                          <div>
                            <label htmlFor='defaultAttempt'>rang par default: </label>
                            <input  name='defaultAttempt' type='number' onChange={handel} value={mision.defaultAttempt} ></input>  
                          </div>
                      </div>
                    :null}    
                </div>   
              </div>
              <div className='mision__body contenair contenair--centre contenair--no-warp'>
              <div className='mision__lor'>
                       <textarea onChange={handel} value={mision.lor} name="lor" id="" cols="30" rows="10"></textarea>   
                  </div>
                  <div className='mision__dificulty contenair contenair--centre'>
                      <select onChange={handel} value={mision.dificulty} name="dificulty" id="">
                          <option value="">N/A</option>
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                      </select> 
                  </div>
                  <div className='mision__regle contnair contenair--scroll'>
                    {mision.regles.filter((regle)=>(!regle.optional)).map((regle,index)=>(<Regles key={`reglesOptional/${index}`} onRightClick={()=>{deleteRegle(index)}} regleinit={regle} className={'regle--campagne--edit'}/>))}
                    {toggleSelect ? null : <div onClick={(e)=>{selectRegle()}}><img src="/add.png" alt="add" /></div>}
                  </div>
                    <div className='mision__regle__optional'>Ou</div>
                  <div className='mision__regle contnair contenair--scroll'>
                    {mision.regles.filter((regle)=>(regle.optional)).map((regle,index)=>(<Regles key={`reglesOptional/${index}`} onRightClick={()=>{deleteRegle(index)}} regleinit={regle} className={'regle--campagne--edit'}/>))}
                    {toggleSelectOptional ? null : <div onClick={(e)=>{selectOptionalRegle()}}><img src="/add.png" alt="add" /></div>}
                  </div>

              </div>
          </div>
        )
    } 


    

  return (
    <div className={`mision ${className} contenair`}>
        <div className='mision__header contenair'>
                 <div className='mision__name'> {mision.name} </div>  
        </div>
        <div className='mision__body contenair contenair--centre contenair--no-warp'>
        <div className='mision__lor'>{mision.lor}</div>
            <div className='mision__dificulty contenair contenair--centre'>
                { mision.dificulty === 0 ? null: <img src='img/dificulty.png' alt='dificulty'/>}
            </div>
            <div className='mision__regle contnair contenair--scroll'>
                {mision.regles.map((regle)=>(<Regles className={'regle--campagne'}/>))}
            </div>
        </div>
    </div>
  )
}
