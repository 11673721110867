import React, { useContext, useEffect, useState } from 'react'
import get from '../../../function/get'
import ExpeditionCard from './ExpeditionCard'
import Loading from '../../Loading'
import postJson from '../../../function/postJson'
import { useNavigate } from 'react-router-dom'
import Carousel from '../../Carousel'
import useFetchAuth from '../../../hook/useFetchAuth'
import { authcontext } from '../../../contexte/AuthContext'

export default function ExpreditionNew({toggleModal,crewId}) {
    const {authFetch} = useContext(authcontext)
    const [campagnes,setCampagnes] = useState(false)
    const [loading,setLoading] = useState(true)
    const fetchAuth = useFetchAuth()
    const nav = useNavigate()
    useEffect(()=>{

        authFetch.get('/campagne')
        .then((campagnes)=>{
            setCampagnes(campagnes)
            setLoading(false)
        }).catch((err)=>{
            console.error(err)
        })
    },[])
    
    const select = (campagne,e)=>{
        authFetch.postJson('/crew/starExpedition',{campagneId:campagne,crewId:crewId})
       .then((expeditionId)=>{
            nav(`/expedition/${crewId}/${expeditionId}`)
        }).catch((err)=>{
            console.error(err)
        })
        //toggleModal(e)
    }
    if(loading){
        return <Loading/>
    }
  return (
    <div className='contenair contenair--centre expedition--select'>
        <Carousel>
            {campagnes.map((campagne)=>(<ExpeditionCard select={select} expediton={campagne} className={'expedition__card--select'}/>))}
        </Carousel>
    </div>
  )
}
