import React, { useContext, useState } from 'react'
import { authcontext } from '../contexte/AuthContext'
import Field from '../componant/form/Field'
import GroupForm from '../componant/form/GroupForm'
import patchJson from '../function/patchJson'
import useFetchAuth from '../hook/useFetchAuth'

export default function MyAccount() {

    const fetchAuth = useFetchAuth()

    const {user,setUser,authServeurFetch} = useContext(authcontext)
    const [data,setData] = useState({
        username:user.username,
        email:user.email,
        password:'',
        newPassword:'',
        confirmNewPassword:''
    })
    const activeModify = (e)=>{
        const name = e.target.dataset.name
    }
    const handelChange = (e)=>{
        const {name,value} = e.target
        setData({...data,[name]:value})
    }
    const form = [
            {
                titel:'Information générales',
                save:(data)=>{
                    return new Promise((resolve,reject)=>{
                        authServeurFetch.patchJson('/change-username',data)
                        .then(()=>{

                            setUser((prevUser)=>({...prevUser,username:data.username}))
                            return resolve("Nom d'utilisateur modifier avec succes")
            
                        }).catch((err)=>{
                            console.log(err)
                           reject(err)
                        })
                    })
                },
                valid:(data)=>{
                    return new Promise((resolve,reject)=>{
                        resolve()
                    })
                },
                fields:[
                        {
                            name:'username',
                            label:"Nom d'utilisateur",
                            type:'text',
                            value:user.username
                        }
                    ],
                fieldsModify:false
                
            },
            {
                titel:'Coordonnées',
                save:(data)=>{
                    return new Promise((resolve,reject)=>{
                        console.log(data)
                        authServeurFetch.patchJson('/change-email',data)
                        .then((res)=>{
                            
                                setUser((prevUser)=>({...prevUser,email:data.email}))
                                return resolve("Email modifier avec succes")
                            
                        }).catch((err)=>{
                            console.log(err)
                           reject(err)
                        })
                    })
                },
                valid:(data)=>{
                    return new Promise((resolve,reject)=>{
                        const rexEmail = /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/gm
                        if(rexEmail.test(data.email)){
                            return resolve()
                        }
                        reject([{for:'email',msg:'Renseigner un email valide'}])
                        
                    })
                },
                fields:[
                        {
                            name:'email',
                            label:"Email",
                            type:'email',
                            value:user.email
                        }
                    ],
                fieldsModify:false
                
            },
            {
                titel:'Mot de passe',
                save:(data)=>{
                    return new Promise((resolve,reject)=>{
                        console.log(data)
                        authServeurFetch.patchJson('/change-password',{password:data.curentPassword,newPassword:data.newPassword})
                        .then((res)=>{
                               return resolve("Mot de passe modifier avec succes")
                        }).catch((err)=>{
                            console.log(err)
                           reject(err)
                        })
                    })
                },
                valid:(data)=>{
                    console.log(data)
                    return new Promise((resolve,reject)=>{
                        if(!data.curentPassword || !data.newPassword){
                            reject([])
                        }
                        if(!data.newPassword){
                            resolve()
                            return
                        }
                        console.log(data.newPassword , data.confirmNewPassword)
                        if(data.newPassword !== data.confirmNewPassword){
                            reject([{for:'confirmNewPassword',msg:'Différent du nouveue mot de passe'}])
                            return
                        }
                        resolve()
                    })
                },
                fields:[
                    {
                        name:"password",
                        label:'Mot de passe',
                        type:'password',
                        value:'••••••••'
                    }
                ],
                fieldsModify:[
                    {
                        name:"curentPassword",
                        label:'Mot de passe acctuelle',
                        type:'password',
                        value:'••••••••' 
                    },
                    {
                        name:"newPassword",
                        label:'Nouveau mot de passe',
                        type:'password',
                        value:'••••••••' 
                    },
                    {
                        name:"confirmNewPassword",
                        label:'confirmer nouveau mot de passe',
                        type:'password',
                        value:'••••••••' 
                    },

                ]
                
            }
        ]
    
  return (
    <div className='Myaccount contenair contenair contenair--centre'>
        <div className='Myaccount__warpper contenair'>
            <h2 className='titel contenair Myaccout__information'>Vos information</h2>
                {form.map((group,index)=>(<GroupForm save={group.save} valid={group.valid} key={`${group.titel}/${index}`} titel={group.titel} fieldsModify={group.fieldsModify} fields={group.fields}/>))}
            <h2 className='titel'>Vos amis</h2>
        </div>
        


    </div>
  )
}
