import React from 'react'
import useFetch from '../hook/useFetch'
import GroupForm from '../componant/form/GroupForm'

export default function PasswordForgot() {
    const fetchPeso = useFetch()
    const form = {
        titel:'Mot de passe oublié',
        save:(data)=>{
            return new Promise((resolve,reject)=>{
                try {
                    fetchPeso('https://auht-api.edo.ovh/forgot-password','postJson',{...data,from:'the-crew.edo.ovh'}).then(()=>{
                        resolve('Un lien pour reinisialiser votre mot de passe vous à étez envoyé')
                    }).catch((err)=>{
                        
                        switch (err) {
                            case 400:
                                reject(new Error('E-mail non valid'))
                                break;
                            case 401 :
                                reject(new Error('Aucun compte lier à cet E-mail'))
                                break;
                            case 403 :
                                reject(new Error('Aucun compte lier à cet E-mail'))
                                break;
                            default:
                                reject(new Error('Une erreur est survenue'))
                                break;
                        }
                    })
                    
                } catch (error) {
                    reject('Une erreur et survenu')
                }
            })
        },
        valid:(data)=>{
            const rexEmail = /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/gm
            return new Promise((resolve,reject)=>{
                let err = []
                if(data.email === ""){
                    err.push({for:'email',msg:'Veillez rensegner ce champ'})
                }else if(!rexEmail.test(data.email)){
                    err.push({for:'email',msg:'Veillez rensegner un email valide'})
                }
                if(err.length > 0){
                    reject(err)
                    return
                }
                    resolve()
                    
            })
        },
        fields:[
            {
                name:'email',
                label:"E-mail",
                type:'email',
                value:""
            },
        ],
        fieldsModify:false,
        options:[
            {
                name:'Se connecter',
                ref:'/login'
            }
        ]
        
    }
  return (

    <div className='contenair contenair--centre'>
            <div className='login__warpper contenair contenair--centre'>
                <img src="logo.png" alt='logo' className='menu_logo'/>
                <GroupForm options={form.options} titel={form.titel} save={form.save} valid={form.valid} input={true} key={'loginForm'} fields={form.fields} btnText={'Envoyer'}/>
            </div>
    </div>
  )
}
