import React, { useContext, useEffect, useState } from 'react'

import Campagne from './Campagne'
import { adminContext } from '../../contexte/AdminContexte'
import postJson from '../../function/postJson'
import Mesage from '../Mesage'
import patchJson from '../../function/patchJson'
import useFetchAuth from '../../hook/useFetchAuth'
import { authcontext } from '../../contexte/AuthContext'

export default function GereeCampagne() {

    const fetchAuth = useFetchAuth()
    const {authFetch} = useContext(authcontext)
    const {campagnes,setCampagnes} = useContext(adminContext)
    const [newCapagneBtn,setNewCampagneBtn] = useState(false)
    const [activeCampagne,setActiveCampagne] = useState(false)
    let id = 0
    const [message,setMessage] = useState({message:'',type:''})

  useEffect(()=>{
    const razMessage = setTimeout(()=>{
      setMessage({...message,message:'',type:''})
    },2000)
    return ()=>{
      clearTimeout(razMessage)
    }
  },[message])

  const save = (campagne)=>{
    const cloneCampagne = {...campagne}
    if(cloneCampagne.id === 'new'){
      delete cloneCampagne.id
      authFetch.postJson('/campagne', {campagne:cloneCampagne})
      .then((id)=>{
        cloneCampagne.id = id
        setCampagnes([...campagnes,cloneCampagne])
        setMessage({...message,message:'Campagne bien enregistrée',type:"ok"})
        setNewCampagneBtn(false)
        setCampagnes([...campagnes,cloneCampagne])
        setActiveCampagne(campagnes[campagne.length -1])
      }).catch((err)=>{
        console.error(err)
        switch (err.message) {
          case "409":
            setMessage({...message,message:'Cette Campagne éxiste déja',type:"nok"})
            break;
          case "500":
            setMessage({...message,message:'Une erreur est survenue',type:"nok"})
          break
          default:
            break;
        }
      })
    }else{
      authFetch.patchJson('/campagne', {campagne:cloneCampagne}) 
      .then((res)=>{
          setMessage({...message,message:'Campagne bien enregistrée',type:"ok"})
      }).catch((err)=>{
        console.error(err)
        switch (err.message) {
          case "409":
            setMessage({...message,message:'Cette Campagne éxiste déja',type:"nok"})
            break;
          case "500":
            setMessage({...message,message:'Une erreur est survenue',type:"nok"})
          break
          default:
            break;
        }
      })
    }
  }

  const newCampagne = (category)=>{
        setNewCampagneBtn(true)
      setActiveCampagne({name:'',jeuBase:category,misions:[],id:'new',lor:'',background:'',numberOfMision:0})  
  }

  return (
    <div className='gereeCampagne contenair '>
      <div className='gereeCampaggne__header contenair'>
        <Mesage message={message}/>
      </div>
      <div className='gereeCampagne__side contenair'>
          <div className='gereeCampagne__side__category'>
            <div>Sous marin</div>
              {campagnes.filter((campagne)=>(campagne.jeuBase === 'sous marin')).map((campagne,index)=>(
                <div key={`sous marin/${index}`} onClick={()=>{setActiveCampagne(false); setTimeout(()=>{setActiveCampagne(campagne)},1) }} className='gereeCampagne__side__name'>{campagne.name}</div>
              ))}
              {newCapagneBtn ? null : <div onClick={()=>{newCampagne('sous marin')}} className='gereeCampagne__side__add'><button className='btn btn--add'><img src="/add.png" alt="add" /></button></div>} 
          </div>
          <div className='gereeCampagne__side__category'>
          <div>Espace</div>
              {campagnes.filter((campagne)=>(campagne.jeuBase === 'espace')).map((campagne,index)=>(
                <div key={`espace/${index}`} onClick={()=>{setActiveCampagne(false); setTimeout(()=>{setActiveCampagne(campagne)},1) }} className='gereeCampagne__side__name'>{campagne.name}</div>
              ))}
              {newCapagneBtn ? null : <div onClick={()=>{newCampagne('espace')}} className='gereeCampagne__side__add'><button className='btn btn--add'><img src="/add.png" alt="add" /></button></div> } 
          </div>
        </div>
      <div className='gereeCampagne__body contenair contenair--column'>
          {activeCampagne ? 
          <Campagne  save={save}  className={'campagne--edit'} campagneInit={activeCampagne}/>:
          <h4 className='titel'>Sélectioner une campagne</h4>
        }
      </div>
        
        
      
        
    </div>
  )
}
