import React, { useContext } from 'react';

import {  Navigate, Outlet, useLocation, useNavigate} from 'react-router-dom';
import { authcontext } from './AuthContext';
import Header from '../componant/header/Header';

const Private = () => {
    const {user} = useContext(authcontext)
    const url = useLocation()
    
    
      
    if(!user){
        return <Navigate to='/login' replace={true} state={{from:url}}/>
    }
    if(url.pathname === '/' || url.pathname === ''){
      return <Navigate to='/home'/>
    }


    return (
      <div className='contenair warpper'>
        <div className='contenair warpper__header'>
          <Header/>      
        </div>
        <div className='contenair warpper__body'>
          <Outlet></Outlet>
        </div>
      </div>
    );
};

export default Private;