import React, { useContext, useEffect, useState } from 'react'
import Dosier from './Dosier'
import Fichier from './Fichier'
import Fichiers from '../class/file';
import Directory from '../class/directory'
import ContextMenu from './ContextMenu';
import { adminContext } from '../contexte/AdminContexte';
import useFetchAuth from '../hook/useFetchAuth';
import { authcontext } from '../contexte/AuthContext';

export default function Exploreur() {
    const {authFetch} = useContext(authcontext)
    const fetchAuth = useFetchAuth()

    const [activeDir,setActiveDir] = useState({})
    const  [loading,setLoading] = useState(true)
    const [showContext,setShowContext] = useState(false)
    const [positionContext,setPositionContext] = useState({top:0,left:0})
    const {dirs,setDirs} = useContext(adminContext)
    function drop(e) {
        e.stopPropagation();
        e.preventDefault()
        const dt = e.dataTransfer
        const files = dt.files
        
       /*  const directory = Directory.findDir(activeDir.path,copyDir) */
        for (const file of files){
            const fichier = new Fichiers({name:file.name,type:file.type,data:file,dir:activeDir.path,path:URL.createObjectURL(file),state:'upload'})
            try {
                const copyDir = {...dirs}
                const fichierInDir = Directory.findFileByName(fichier,copyDir) 
                fichierInDir.state = 'remplacer'
                fichierInDir.data =  file
                setDirs(copyDir)
            } catch (error) {
                if(error.message === "file doesn't exist"){
                    setDirs((prevDir)=>{
                        const copyDir = {...prevDir}
                        const directory = Directory.findDir(activeDir.path,copyDir)
                        directory.child.push(fichier)
                        return copyDir
                    })
                    authFetch.post('/uploadImg',fichier.upload())
                    .then(()=>{
                        setDirs((prevDir)=>{
                            const copyDir = {...prevDir}
                            const fichierInDir = Directory.findFileByName(fichier,copyDir)
                            console.log('uploadok')
                            fichierInDir.state = 'ok'
                            fichierInDir.path = fichier.dir + '/' + fichier.name
                            console.log(fichierInDir)
                            return copyDir
                        }) 
                    }).catch((err)=>{
                        switch (err) {
                            case 409 || 500:

                            setDirs((prevDir)=>{
                                    const copyDir = {...prevDir}
                                    const fichierInDir = Directory.findFileByName(fichier,copyDir)
                                    fichierInDir.state = err === 409 ? 'remplacer' : 'nok'
                                    console.log(fichierInDir)
                                    return copyDir
                                }) 
                                break;
                    
                            default:
                                console.error(err)
                                break;
                        }
                        console.log(err)
                    })
                }else{
                    console.error(error)
                }
            }
        }
        /* for (const file of files){
            const fichier = new Fichiers({name:file.name,type:file.type,data:file,dir:activeDir.path,path:URL.createObjectURL(file),state:'upload'})
            try {
               const fichierInDir = Directory.findFileByName(fichier,copyDir)
                fichierInDir.state = 'remplacer'
                fichierInDir.data =  file
                fichierInDir.path = URL.createObjectURL(file)
                setDirs(copyDir)
            } catch (error) {
                if(error.message === "file doesn't exist"){
                    directory.child.push(fichier)
                    setDirs(copyDir)
                    fichier.upload(directory.path).then(()=>{
                        const copyDir = {...dirs}
                        const fichierInDir = Directory.findFileByName(fichier,copyDir)
                        console.log('ok')
                        fichierInDir.state = 'ok'
                        fichierInDir.path = fichier.dir + '/' + fichier.name
                        console.log(fichierInDir)
                        setDirs(copyDir) 
                    }).catch((err)=>{
                        const fichierInDir = Directory.findFileByName(fichier,copyDir)
                        switch (err) {
                            case 409:
                                fichierInDir.state = 'remplacer'
                                setDirs(copyDir)
                                break;
                            case 500:
                                fichierInDir.state = 'nok'
                                setDirs(copyDir)
                                break;
                            default:
                                console.error(err)
                                break;
                        }
                    })
                }else{
                    console.error(error)
                }
            }
        } */
        

    }

    function DragOver(e) {
        e.stopPropagation();
        e.preventDefault()
    }

    function DragEnter(e) {
        e.stopPropagation();
        e.preventDefault()
    }
    
    useEffect(()=>{
        setActiveDir(dirs)
        setLoading(false)
    },[setActiveDir])

    const handelContext = (e)=>{
        e.preventDefault()
        e.stopPropagation()
        setShowContext(false)
        console.log(e)
        setPositionContext({
            top:e.clientY,
            left:e.clientX
        })
        console.log(positionContext )
        setShowContext(true)
    }
    

    const handelNewDIr = ()=>{
        const cloneDIR = {...dirs}
        console.log(activeDir)
        const directory = Directory.findDir(activeDir.path,cloneDIR)
        directory.child.push(new Directory({name:'new',state:'new',type:'directory',child:[],dir:directory.path}))
        setDirs(cloneDIR)
    }
    

    if(loading){
        return(
            <div>loading</div>
        )
    }

    return (
        <div className ='grid grid__4c exploreur'>
            
            <div className='exploreur__top-bar contenair conternair'>
                {activeDir.path}
            </div>
            <div  className='exploreur__side-bar contenair conternair contenair--column'>

                <Dosier onDoubleClick={(e)=>{e.stopPropagation(); setActiveDir(dirs)}} setActiveDir={setActiveDir} dir={dirs} DIR={dirs} setDir={setDirs} className={'dir--side-bar'}/>
            </div>
            <div onDrop={drop} onDragOver={DragOver} onDragEnter={DragEnter} onContextMenu={handelContext} className='exploreur__main conternair contenair--column contenair--scroll'>
                {showContext && <ContextMenu position={positionContext} setShowContext={setShowContext}>
                    <div  onClick={handelNewDIr} className='context-menu__option'>nouveau dosier</div>
                    </ContextMenu>}
                {
                    activeDir.child.map((element)=>{
                        if(element.type === 'directory'){
                            if(element.state === 'new'){
                                return <Dosier key={'mainDosier/'+element.name} dir={element} DIR={dirs} setDir={setDirs} className={'dir--main dir--edit'}/>
                            }
                            return <Dosier key={'mainDosier/'+element.name} onDoubleClick={()=>{setActiveDir(element)}} dir={element} DIR={dirs} setDir={setDirs} className={'dir--main'}/>
                        }
                        return <Fichier key={'file'+element.name} file={element} DIR={dirs} setDir={setDirs} className={'file--main'}/>
                    })
                }
            </div>
        </div>
    )
}
