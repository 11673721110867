import post from "../function/post"
import Fichiers from "./file"


class Directory{
    static findDir = (path,DIR)=>{
        if(DIR.path === path){
            return DIR
        }else{
            const i =  DIR.child.findIndex((element)=>(path.includes(element.path)))
            if(i !== -1){
                return  Directory.findDir(path,DIR.child[i])         
            }else{
                throw new Error("directory doesn't exist")
            }
        }
              
    }

    static findFile = (file,DIR)=>{
       const  dir = Directory.findDir(file.dir,DIR)
        const i =  dir.child.findIndex((element)=>(element.path === file.path))      
        if(i !== -1){
            return dir.child[i] 
        }
        throw new Error("file doesn't exist")
    }

    static findFileByName = (file,DIR)=>{
        const  dir = Directory.findDir(file.dir,DIR)
        const i =  dir.child.findIndex((element)=>(element.name === file.name))
        if(i !== -1){
            return dir.child[i] 
        }
        throw new Error("file doesn't exist")      
    }

    constructor(dir){
        this.name = dir.name
        this.path = dir.path
        this.child = dir.child.map((element)=>{
            if(element.type === "directory"){
                return new Directory(element)
            }
            return new Fichiers(element)
        })
        this.expand = dir.expand
        this.state = dir.state
        this.type = dir.type
        this.state = dir.state
        this.dir = dir.dir
    }
    
    deleteDir = ()=>{
            const data = new FormData()
            console.log(this.path)
            data.append('path',this.path)
            return data
    }

    newDir = (name)=>{
            const data = new FormData()
            data.append('dir',this.dir)
            data.append('name',name)
            return data  
    }

}
export default Directory
