import React, { useContext, useEffect, useState } from 'react'
import { desktopContext } from '../desktop/Desktop'
import ImgFetch from '../ImgFetch'

export default function Regles({saveRegle,className,regleinit = {name:"nouvelle regle",instruction:"",img:"image.png",id:'new',jeuDeBase:'',isInfinite:false} ,jeubase, onRightClick}) {
    const [regle,setRegle] = useState(regleinit)
    const {parcourir} = useContext(desktopContext)
    const [init,setInit] = useState(false)
    const [sync,setSync] = useState(regleinit.id === 'new'?false : true)

    const setImg = (path)=>{
        setSync(false)
        const cloneRegle = {...regle}
        cloneRegle.img = path
        setRegle(cloneRegle)
    }
    const save = ()=>{
        saveRegle({...regle,jeuDeBase:jeubase},setSync)
    }
    const handel = (e)=>{
        setSync(false)
        setRegle((prevRegle)=>({...prevRegle, [e.target.name]: e.target.value}))
        
    }

   

    const onContext = (e)=>{
        e.preventDefault()
        onRightClick()
    }


    useEffect(()=>{
        
        if(init && className === 'edit'){
            const raz = setTimeout(()=>{
                save()
            },750)
            return ()=>{
                clearTimeout(raz)
            }
        }
        setInit(true)
    },[regle])

    if(className.includes('regle--campagne--edit')){
        return (
        <div onContextMenu={onContext} className='regle regle--campagne contenair contenair--centre'>
            <ImgFetch src={regle.img } crossOrigin='use-credentials' alt="icon regle"/>

            <div className='regle__info'>
                <div className='regle__name'>
                    {regle.name}
                </div>  
                
            </div>
        </div> 
        )
    }

    if(className.includes('regle--campagne')){
        return (
            <div className='regle regle--campagne contenair contenair--centre'>
            <ImgFetch src={regle.img !=='image.png'? regle.img : '/image.png'} alt="icon regle"/>    
            <div className='regle__info'>
                <div className='regle__name'>
                    {regle.name}
                </div>
                <div className="regle__jeu">
                    {regle.jeuDeBase}
                </div>    
            </div>
        </div> 
        )
    }
    
if(className === 'edit'){
    
    return (
        <div className={`regle regle--edit contenair contenair--centre contenair--column ${sync ? 'regle--sync': 'regle--unsync'}`}>
            <div className='regle__info contenair'>
                <ImgFetch onClick={(e)=>{parcourir(setImg,e)}} src={regle.img !=='image.png'? regle.img : '/image.png'}  alt="icon regle"/>
                <div className='regle__name'>
                    <label htmlFor="name">nom de la regle :</label>
                    <input onChange={handel} type="text"  name='name' value={regle.name}/>
                </div>
        
            </div>
            <div className='regle__instruction contenair contenair--centre'>
                <textarea onChange={handel} name="instruction" placeholder='expilication de la régle' id="" value={regle.instruction} cols="30" rows="10"></textarea>
            </div>
    
            {/* <button onClick={save} className='btn btn--big '>Enregistrer</button> */}
        </div>
      )

}
 
}
