import React, { createContext, useCallback, useState } from 'react'
import Window from '../Window'
import Exploreur from '../Exploreur'
import Icon from './Icon'
import Parcourir from '../Parcourir'
import GereeRegle from '../regles/GereeRegle'
import GereeCampagne from '../campagne/GereeCampagne'
import AdminContexte from '../../contexte/AdminContexte'
export const desktopContext = createContext()
export default function Desktop() {
    const [windows,setWindows] = useState([])
    console.log(windows)

   const start = useCallback((activity,position={top:0,left:0,height:500,widht:750})=>{
    const cloneWidows = [...windows]
    cloneWidows.push({...activity,position})
    setWindows(cloneWidows)
  },[windows]) 

    const pullWindow = (index,e)=>{
      e.stopPropagation()
      const cloneWidows = [...windows]
      cloneWidows.push(cloneWidows.splice(index,1))
      setWindows(cloneWidows)
    }

    const closeWindow = (index)=>{
      const cloneWidows = [...windows]
      cloneWidows.splice(index,1)
      setWindows(cloneWidows)
    }

    const parcourir = useCallback((select,e)=>{
      console.log(e)
      start({activity:<Parcourir select={select}/>,name:'parcourir',img:'/parcourir'},{top:e.clientY,left:e.clientX,height:500,widht:250})
    },[start]) 

    const icons = [<Icon key={'expoleur/icon'} img={'/folder.png'} name={"exploreur"} start={start}>{<Exploreur/>}</Icon>,<Icon start={start} key={'regle/icon'} name={"gerée les regle"} img={"/gestion-des-regel.png"}>{<GereeRegle parcourir={parcourir}/>}</Icon>,<Icon key={'campagne/icon'} name={'gérée les campagnes'} start={start} img={"/gestion-des-regel.png"}>{<GereeCampagne/>}</Icon>]
    return (
      <AdminContexte>
        <desktopContext.Provider value={{parcourir,start,closeWindow}}>
          <div className='contenair desktop'>
              <div className='grid desktop__main'>
                  {icons.map((icon)=>(icon))}
                  {windows.map((activity,index)=>(<Window key={`window/${index}`} name={activity.name} position={activity.position} close={()=>{closeWindow(index)}} onClick={(e)=>{pullWindow(index,e)}} zIndex={index}>{activity.activity}</Window>))}
              </div>
              <div className="contenair desktop__task-bar">
                {windows.map(({name,img},index)=>(<div key={`task/${index}`} className='desktop__task-bar__activity contenair contenair--centre'>
                  <img src={img} alt="" />
                  <span>{name}</span>
                </div>))}
              </div>
          </div>
        </desktopContext.Provider>  
      </AdminContexte>
  )
}
